<template>
  <div
    :class="[
      '[&_p]:u-text-[12px] [&_p]:u-leading-[20px] [&_p]:u-text-[#666666] sm:[&_p]:u-text-[16px] sm:[&_p]:u-leading-[26px]',
      '[&_h1]:u-my-[30px] [&_h1]:u-text-3xl sm:[&_h1]:u-text-4xl',
      '[&_h2]:u-my-[30px] [&_h2]:u-text-2xl sm:[&_h2]:u-text-3xl',
      '[&_h3]:u-my-[30px] [&_h3]:u-text-xl sm:[&_h3]:u-text-2xl',
      '[&_h4]:u-my-[30px] [&_h4]:u-text-lg sm:[&_h4]:u-text-xl',
      '[&_h5]:u-my-[16px] [&_h5]:u-text-lg sm:[&_h5]:u-text-lg',
      '[&_h6]:u-my-[16px] [&_h6]:u-text-[14px] sm:[&_h6]:u-text-[16px]',
      '[&_blockquote]:u-mx-0 [&_blockquote]:u-my-[30px] [&_blockquote]:u-p-[10px] [&_blockquote]:u-border-solid [&_blockquote]:u-border-0 [&_blockquote]:u-border-s-4 [&_blockquote]:u-border-gray-300 [&_blockquote]:u-bg-gray-50',
      '[&_a]:u-text-[#fcb400]',
      '[&_strong]:u-font-bold',
      '[&_em]:u-italic',
      '[&_ol]:u-my-[30px]',
      '[&_ul]:u-my-[30px]'
    ]">
    <PortableText :value="props.value" :components="PortableTextComponents" />
  </div>
</template>

<script setup>
import { PortableText } from '@portabletext/vue'
import { h } from 'vue'

const props = defineProps(['value'])

/** @type {Partial<import('@portabletext/vue').PortableTextVueComponents>} */
const PortableTextComponents = {
  types: {
    image: ({ value }) => h('img', { src: value.imageUrl }),
    callToAction: ({ value, isInline }) =>
      isInline
        ? h('a', { href: value.url }, value.text)
        : h('div', { class: 'callToAction' }, value.text)
  },

  marks: {
    link: ({ value }, { slots }) =>
      h(
        'a',
        {
          href: value.href,
          rel: !value.href.startsWith('/') ? 'noreferrer noopener' : undefined
        },
        slots.default?.()
      ),
    left: (_, { slots }) =>
      h('div', { style: 'text-align: left' }, slots.default?.()),
    center: (_, { slots }) =>
      h('div', { style: 'text-align: center' }, slots.default?.()),
    right: (_, { slots }) =>
      h('div', { style: 'text-align: right' }, slots.default?.()),
    textColor: ({ value }, { slots }) =>
      h('span', { style: `color: ${value.value}` }, slots.default?.()),
    highlightColor: ({ value }, { slots }) =>
      h(
        'span',
        { style: `background-color: ${value.value}` },
        slots.default?.()
      )
  }
}
</script>
