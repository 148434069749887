import { api } from 'src/boot/axios'
import { useUserStore } from 'stores/user'
import { useI18n } from 'vue-i18n'

export function packageReq() {
  return api({
    url: 'package/all',
    method: 'post',
    data: {}
  })
}

export function TestTicket() {
  return api({
    url: 'notice/ticket',
    method: 'post',
    data: {}
  })
}

export function paymentType() {
  const { t } = useI18n()

  return {
    data: [
      {
        id: 1,
        label: t('pricing.creditCard'),
        value: 'BANK_CARD',
        color: 'yellow-9',
        icon: ['visa', 'mastercard']
      },
      {
        id: 2,
        label: t('pricing.unionPay'),
        value: 'UNION_BANK_CARD',
        color: 'yellow-9',
        icon: ['unionpay']
      },
      {
        id: 3,
        label: t('pricing.crypto'),
        value: 'crypto',
        color: 'yellow-9',
        icon: ['crypto-btc', 'crypto-eth', 'crypto-usdt']
      }
      // {
      //   id: 3,
      //   label: isEnglish ? 'Ali Pay' : '阿里支付',
      //   value: 'ALI_PAY',
      //   color: 'yellow-9',
      //   icon: ['ali']
      // },
      // {
      //   id: 4,
      //   label: t('pricing.wechatPay'),
      //   value: 'WECHAT_PAY',
      //   color: 'yellow-9',
      //   icon: ['wechatpay']
      // }
      // { label: 'Paypal', value: 'PAYPAL', color: 'primary', icon: 'paypal' },
      // {
      //   label: 'Google Pay',p
      //   value: 'GOOGLE_PAY',
      //   color: 'primary',
      //   icon: ['google']
      // }
    ]
  }
}

export function pageCommissionOrderReq(pageNum, pageSize) {
  return api({
    url: 'order/pageCommissionOrder',
    method: 'post',
    headers: {
      DeviceId: useUserStore().userLoginInfo.deviceId
    },
    data: {
      PageSize: pageSize,
      PageNum: pageNum
    }
  })
}
