export const CATEGORIES_QUERY = `*[
  _type == "category" &&
  !(slug.current in ["announcement", "banner"])
] | order(slug.current asc) {
  "id": _id,
  "createdAt": _createdAt,
  "updatedAt": _updatedAt,
  "title": title[_key == $lng][0].value,
  "slug": slug.current
}`

export const ARTICLES_COUNT_QUERY = `count(*[
  _type == "article" &&
  language == $lng &&
  dateTime(now()) >= dateTime(publishedAt) &&
  (
    !defined($category) ||
    $category in categories[]->slug.current ) &&
  (
    !defined($keywords) ||
    title match $keywords + '*' ||
    pt::text(content) match $keywords + '*' )
])`

/**
 * TODO 優化
 * [Avoid joins in filters](https://www.sanity.io/docs/high-performance-groq#68c7065755fd)
 * [Avoid resolving assets](https://www.sanity.io/docs/high-performance-groq#59a0d6dbea9f)
 * [@sanity/image-url](https://www.sanity.io/docs/image-url)
 */
export const ARTICLES_QUERY = `*[
  _type == "article" &&
  language == $lng &&
  dateTime(now()) >= dateTime(publishedAt) &&
  (
    !defined($category) ||
    $category in categories[]->slug.current ) &&
  (
    !defined($keywords) ||
    title match $keywords + '*' ||
    pt::text(content) match $keywords + '*' )
] | order(publishedAt desc)[$offset...$offset+$limit] {
  "id": _id,
  "createdAt": _createdAt,
  "updatedAt": _updatedAt,
  title,
  "slug": *[_type == "translation.metadata" && references(^._id)][0].slug.current,
  language,
  metaData,
  author,
  "mainImage": mainImage.asset->url,
  "categories": {
    "raw": categories[]->
  } | {
    "filtered": raw[!(slug.current in ["announcement", "banner"])] { "id": _id, "title": title[_key == $lng][0].value, "slug": slug.current }
  }.filtered,
  publishedAt,
  "plainText": pt::text(content),
}`

export const ARTICLE_QUERY = `*[
  _type == "translation.metadata" &&
  slug.current == $slug
][0].translations[_key == $lng][0].value->{
  "id": _id,
  "createdAt": _createdAt,
  "updatedAt": _updatedAt,
  title,
  "slug": $slug,
  language,
  metaData,
  author,
  "mainImage": mainImage.asset->url,
  "categories": {
    "raw": categories[]->
  } | {
    "filtered": raw[!(slug.current in ["announcement", "banner"])] { "id": _id, "title": title[_key == $lng][0].value, "slug": slug.current }
  }.filtered,
  publishedAt,
  "body": content[] {
    ..., // Include all existing properties of the body field
    _type == "image" => {
      "imageWidth": asset->metadata.dimensions.width,
      "imageHeight": asset->metadata.dimensions.height
    },
    _type == "gallery" => {
      "images": images[]{
        ...,
        "imageWidth": asset->metadata.dimensions.width,
        "imageHeight": asset->metadata.dimensions.height
      }
    }
  }
}`

export const SIMILAR_ARTICLES_QUERY = `{
  "doc": *[
    _type == "translation.metadata" &&
    slug.current == $slug
  ][0].translations[_key == $lng][0].value->
} | {
  "_id": doc._id,
  "categoryIds": (doc.categories[]->)[!(@.slug.current in ["announcement", "banner"])]._id
} | {
  "filtered": *[
    _type == "article" &&
    language == $lng &&
    _id != ^._id &&
    dateTime(now()) >= dateTime(publishedAt) &&
    (!defined(^.categoryIds) || array::intersects(categories[]._ref, ^.categoryIds))
  ]
}.filtered | order(publishedAt desc)[0...3] {
  "id": _id,
  "createdAt": _createdAt,
  "updatedAt": _updatedAt,
  title,
  "slug": *[_type == "translation.metadata" && references(^._id)][0].slug.current,
  language,
  author,
  "mainImage": mainImage.asset->url,
  "categories": (categories[]->)[
    !(slug.current in ["announcement", "banner"])
  ] {
    "id": _id,
    "title": title[_key == $lng][0].value,
    "slug": slug.current
  },
  publishedAt,
  "plainText": pt::text(content),
}`

export const FAQ_CATEGORIES_QUERY = `*[
  _type == "faqCategory"
] | order(slug.current asc) {
  "id": _id,
  "createdAt": _createdAt,
  "updatedAt": _updatedAt,
  "title": title[_key == $lng][0].value,
  "slug": slug.current
}`

export const FAQS_COUNT_QUERY = `count(*[
  _type == "faq" &&
  language == $lng &&
  (
    !defined($category) ||
    $category in categories[]->.slug.current ) &&
  (
    !defined($keywords) ||
    title match $keywords + '*' ||
    pt::text(content) match $keywords + '*' )
])`

export const FAQS_QUERY = `*[
  _type == "faq" &&
  language == $lng &&
  (
    !defined($category) ||
    $category in categories[]->.slug.current ) &&
  (
    !defined($keywords) ||
    title match $keywords + '*' ||
    pt::text(content) match $keywords + '*' )
] | order(_updatedAt desc)[$offset...$offset+$limit] {
  "id": _id,
  "createdAt": _createdAt,
  "updatedAt": _updatedAt,
  title,
  "categories": categories[]->{ "id": _id, "title": title[_key == $lng][0].value, "slug": slug.current },
  "content": content[] {
    ..., // Include all existing properties of the body field
    _type == "image" => {
      "imageWidth": asset->metadata.dimensions.width,
      "imageHeight": asset->metadata.dimensions.height
    },
    _type == "gallery" => {
      "images": images[]{
        ...,
        "imageWidth": asset->metadata.dimensions.width,
        "imageHeight": asset->metadata.dimensions.height
      }
    }
  }
}`

export const REVIEWS_QUERY = `*[
  _type == "review" &&
  dateTime(now()) >= dateTime(publishedAt)
] | order(publishedAt desc) {
  "id": _id,
  "author": author->{
    name,
    position,
    "avatarImage": image.asset->url,
  },
  rating,
  title,
  "content": pt::text(content),
}`
