import { api } from 'src/boot/axios'
import { useUserStore } from 'src/stores/user'

export function checkoutReq(data) {
  return api({
    url: 'order/create',
    method: 'post',
    headers: {
      DeviceId: data.deviceId
    },
    data: {
      Pid: +data.pid,
      Type: data.type,
      BackUrl: data.backUrl,
      RedirectUrl: data.redirectUrl,
      Locale: data.locale
    }
  })
}

export function checkoutRep(data) {
  if (!data) return
  if (
    data.paymentType === 'reddot-web' ||
    data.paymentType === 'reddot-web-union' ||
    data.paymentType === 'reddot-web-ali' ||
    data.paymentType === 'reddot-web-wechat'
  ) {
    return {
      orderNumber: data.orderNumber,
      paymentType: data.paymentType,
      paymentUrl: data.paymentUrl
    }
  }
  return {
    clientSecret: data.clientSecret,
    paymentType: data.paymentType
  }
}

export function redDotRedirectReq(data) {
  return api({
    url: 'order/reddotRedirect',
    method: 'post',
    headers: {
      DeviceId: useUserStore().userLoginInfo.deviceId
    },
    data: {
      TransactionId: data.transactionId,
      Type: data.type
    }
  })
}

export function redDotRedirectRep(data) {
  if (!data) return
  return {
    createdTime: data.request_timestamp || '--',
    amount: data.request_amount || '--',
    package: data.merchant_reference
      ? data.merchant_reference.split(': ')[1]
      : '--',
    orderId: data.order_id || '--',
    ccy: data.request_ccy || '',
    success: +data.response_code || '--',
    name: data.payer_name || '--',
    cardNum: data.last_4 ? 'xxxxxx' + data.last_4 : '--'
  }
}

export function checkPurchaseLimit(data) {
  return api({
    url: 'order/checkPurchaseLimit',
    method: 'post',
    headers: {
      DeviceId: data.deviceId
    },
    data: {
      Pid: data.pid
    }
  })
}

export function getPaymentOrderReq(pageNum = 1, pageSize = 10) {
  return api({
    url: 'order/page',
    method: 'post',
    headers: {
      DeviceId: useUserStore().userLoginInfo.deviceId
    },
    data: {
      pageNum,
      pageSize
    }
  })
}

export function getOnePaymentOrderReq({ orderNumber }) {
  return api({
    url: 'order/get',
    method: 'post',
    headers: {
      DeviceId: useUserStore().userLoginInfo.deviceId
    },
    data: {
      orderNumber
    }
  })
}
