import { api } from 'src/boot/axios'

export function getVersionDetailsReq(platform) {
  return api({
    url: 'version/platform',
    method: 'post',
    data: {
      Platform: platform
    }
  })
}

export function getVersionDetailsRep(data) {
  if (!data) return
  return {
    version: data.Version,
    platform: data.Platform,
    url: data.Url
  }
}
