<template>
  <div class="u-inline u-cursor-pointer" v-bind="attrs" @click="open">
    <slot />
  </div>
  <q-dialog v-model="isDialogActived" class="large">
    <q-card class="u-flex u-flex-col">
      <q-card-section
        class="q-dialog__message u-flex-1 u-text-left u-overflow-auto">
        <component :is="activedDialog" />
      </q-card-section>
      <q-card-actions class="u-flex-none">
        <q-btn flat :label="$t('global.close')" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed, defineComponent, onMounted, ref, useAttrs } from 'vue'
import { useUserStore } from 'src/stores/user'
import PrivacyPolicy from './PolicyStatement/PrivacyPolicy.vue'
import PrivacyPolicyZh from './PolicyStatement/PrivacyPolicyZh.vue'
import TermOfService from './PolicyStatement/TermOfService.vue'
import TermOfServiceZh from './PolicyStatement/TermOfServiceZh.vue'

const Name = {
  PrivacyPolicy: 'PrivacyPolicy',
  privacy: 'PrivacyPolicy',
  TermOfService: 'TermOfService',
  term: 'TermOfService'
}

export default defineComponent({
  components: {
    PrivacyPolicy,
    PrivacyPolicyZh,
    TermOfService,
    TermOfServiceZh
  },
  props: {
    name: { type: String }
  },
  setup(props) {
    const userStore = useUserStore()
    const isDialogActived = ref(false)
    const attrs = useAttrs()

    const open = () => {
      isDialogActived.value = true
    }

    const activedDialog = computed(() => {
      const name = Name[props.name]
      const lang = userStore.lang.slice(0, 2).toLowerCase() === 'zh' ? 'Zh' : ''
      return name ? name + lang : ''
    })

    onMounted(async () => {
      if (props.name === 'privacy' || props.name === 'term') {
        isDialogActived.value = true
      }
    })

    return {
      activedDialog,
      isDialogActived,
      attrs,
      open
    }
  }
})
</script>

<style lang="scss" scoped>
:deep() {
  h1 {
    margin-top: 1em;
    margin-bottom: 29px;
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #1c1c1c;
  }
  h1 sub {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
  }
  h2 {
    margin: 1em 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #1c1c1c;
  }
  p {
    font-size: 14px;
    margin: 1em 0;
  }
  ul,
  ol {
    font-size: 14px;
    margin: 1em 0;
    padding-inline-start: 1.8em;
  }

  @media screen and (min-width: 640px) {
    h1 {
      font-size: 36px;
      line-height: 32px;
    }
    h1 sub {
      font-size: 16px;
      line-height: 20px;
    }
    h2 {
      font-size: 20px;
      line-height: 24px;
    }
    p {
      font-size: 16px;
    }
    ul,
    ol {
      font-size: 16px;
    }
  }
}
</style>
