import { defineStore } from 'pinia'
import { getPost, getReviews } from 'src/api/cms'
import { getCategories, getFaqCategories, getPosts, getFaqs } from 'src/api/cms'

/**
 * @typedef {import('src/api/cms').CategoriesQueryResult} CategoriesQueryResult
 * @typedef {import('src/api/cms').FaqCategoriesQueryResult} FaqCategoriesQueryResult
 * @typedef {import('src/api/cms').FaqsQueryParams} FaqsQueryParams
 * @typedef {import('src/api/cms').FaqsQueryResult} FaqsQueryResult
 * @typedef {import('src/api/cms').PostsQueryResult} PostsQueryResult
 * @typedef {import('src/api/cms').PostQueryParams} PostQueryParams
 * @typedef {import('src/api/cms').Post} Post
 */

export const usePostStore = defineStore('post', {
  state: () => ({
    /** @type {CategoriesQueryResult} */
    categories: {
      list: [],
      count: 0
    },

    /** @type {PostsQueryResult} */
    banners: {
      list: [],
      count: 0
    },

    /** @type {PostsQueryResult} */
    posts: {
      nextPageParam: null,
      list: [],
      count: 0
    },

    /** @type {Post} */
    post: {},

    /** @type {FaqsQueryResult} */
    faqs: {
      nextPageParam: null,
      list: [],
      count: 0
    },

    /** @type {FaqCategoriesQueryResult} */
    faqCategories: {
      list: [],
      count: 0
    },

    reviews: {
      list: [],
      count: 0
    }
  }),
  actions: {
    /** @param {FaqsQueryParams} params */
    async resumePageBlog(params) {
      this.categories = await getCategories({ lng: params.lng })

      this.banners = await getPosts({ lng: params.lng, category: 'banner' })

      this.posts = await getPosts(params)
    },

    async getPostNextPage() {
      const pageParam = this.posts.nextPageParam
      const result = await getPosts(pageParam)

      this.posts.nextPageParam = result.nextPageParam
      this.posts.list = this.posts.list.concat(result.list)
      this.posts.count = result.count
    },

    /** @param {PostQueryParams} params */
    async resumePageArticle(params) {
      this.post = await getPost(params)
    },

    /** @param {FaqsQueryParams} params */
    async resumePageFaq(params) {
      this.faqCategories = await getFaqCategories({ lng: params.lng })

      this.faqs = await getFaqs(params)
    },

    async getFaqNextPage() {
      const pageParam = this.faqs.nextPageParam
      const result = await getFaqs(pageParam)

      this.faqs.nextPageParam = result.nextPageParam
      this.faqs.list = this.faqs.list.concat(result.list)
      this.faqs.count = result.count
    },

    async resumePageHome() {
      this.reviews = await getReviews()
    }
  }
})
