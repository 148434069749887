import { computed, ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { getVersionDetailsRep, getVersionDetailsReq } from 'src/api/version'
import { useQuasar } from 'quasar'

function detectOS({
  query = '',
  userAgent = {
    Android: false,
    iOS: false,
    macOS: false,
    Windows: false
  },
  fallback = 'Android'
} = {}) {
  const source = {
    Android: true,
    iOS: true,
    macOS: true,
    Windows: true
  }
  if (query && source[query]) {
    return query
  }
  return Object.keys(source).find((v) => userAgent[v]) ?? fallback
}

export default function usePlatformVersion() {
  const $q = useQuasar()
  const route = useRoute()

  const store = ref({
    version: '',
    platform: detectOS({
      query: route.query.platform,
      userAgent: {
        Android: $q.platform.is.android,
        iOS: $q.platform.is.ios,
        macOS: $q.platform.is.mac,
        Windows: $q.platform.is.win
      }
    }),
    url: ''
  })

  const platform = computed(() => {
    return detectOS({
      query: route.query.platform,
      userAgent: {
        Android: $q.platform.is.android,
        iOS: $q.platform.is.ios,
        macOS: $q.platform.is.mac,
        Windows: $q.platform.is.win
      }
    })
  })

  watchEffect(() => {
    getVersionDetailsReq(platform.value)
      .then((res) => {
        store.value = getVersionDetailsRep(res.data)
      })
      .catch((err) => {
        console.log(err)
        store.value = {
          version: '',
          platform: platform.value,
          url: ''
        }
      })
  })

  return store
}
