<template>
  <h1>
    隐私协议
    <br />
    <sub>(本政策最新修订日期为二零二三年一月二十六日)</sub>
  </h1>
  <p>
    GoDuck VPN
    尊重并全力保护用户隐私。我们希望您了解我们收集的信息，不收集的信息以及我们如何收集、使用和存储信息。我们不收集您的活动日志，包括不记录浏览历史记录、流量数据、数据内容或
    DNS 查询。我们也从不存储连接日志，这意味着您的 IP
    地址、连接时间或会话持续时间都不会记录日志。
  </p>
  <h2>同意</h2>
  <p>
    我们非常注重用户隐私，因此制定隐私政策。请您仔细阅读这些隐私政策的内容，以协助本平台为您提供满意的服务。本政策内容涵盖本平台如何经由使用本平台而收集所得的个人资料。
    使用本网站服务，即表示您已同意接受本平台的隐私政策，并同意本平台按照以下政策收集个人资料及使用方法。
  </p>
  <h2>信息收集</h2>
  <p>
    当您使用本平台服务时，您可以选择绑定邮箱，但是邮箱不是必须的，不填写也不会影响账号使用。我们非常尊重用户个人信息，不会为满足第三方营销目的而向其出售或出租您的任何信息。我们可能会在下列情况下将您的信息与第三方共享：
  </p>
  <p>
    比如说，我们在使用第三方平台工具来分析用户数据时可能会使用您的信息。我们会通过您提供的联系方式向您发送服务通知以及对您的客服请求作出回应。您可以登录本平台修改您的个人资料。
  </p>
  <p>GoDuck VPN 使用您的电子邮件地址的原因如下：</p>
  <ul>
    <li>提供指向我们网站的链接，包括密码重置电子邮件；</li>
    <li>与您交流有关您的 服务或响应您的交流；</li>
    <li>
      发送营销信息，您可以按照这些电子邮件中所述的退出程序选择不接收营销电子邮件。
    </li>
  </ul>
  <p>
    GoDuck VPN
    仅将您的个人信息用于本隐私政策中列出的目的，我们不会将您的个人信息出售给第三方。
  </p>
  <h2>信息传输</h2>
  <p>
    我们的服务遍布全球，因此，您的数据将通过我们的全球服务器传输，这些服务器可能不在您居住的国家。我们依靠某些第三方服务提供商来使我们能够为您提供我们的服务。每当我们传输您的信息时，我们都会采取措施对其进行保护。
  </p>
  <p>
    您承认并了解，您的信息将按照说明进行信息传输，这是提供我们的服务并遵守我们的服务条款所必需的。
  </p>
  <p>
    https://dznhtt1ebe.execute-api.us-east-2.amazonaws.com 为GoDuck
    为完善所用的Amazon AWS 云服务地址。此地址仅作为GoDuck
    储存用户已知晓并同意授权的信息所用，并仅由GoDuck开发团队管理并保存。如隐私协议所述，GoDuck
    将不会把储存在https://dznhtt1ebe.execute-api.us-east-2.amazonaws.com
    上的信息分享，传播给任何第三方使用。
  </p>
  <h2>信息安全</h2>
  <p>
    您也有责任保护及妥善存放个人资料，避免通过任何途径（包括电话以及电邮）向其他人分享或公开您在本平台的登录密码。
  </p>
  <p>
    必要时，我们会使用强大的加密算法来安全地保护所有公钥和私钥以及密码。采取了适当的安全措施来保护此信息，防止未经授权的访问，未经授权的更改，破坏或泄露数据。
  </p>
  <p>
    GoDuck VPN
    员工，承包商和代理商对个人信息的访问受到限制。这些员工要求提供信息以运营、发展或改善我们的服务，但受保密义务的约束。如果不履行这些义务，他们可能会受到纪律处分，包括解雇和刑事起诉。
  </p>
  <h2>Cookies</h2>
  <p>GoDuck VPN 使用几种不同类型的 cookie 来改善网站上的用户体验，例如：</p>
  <ul>
    <li>Google Analytics（分析），用于统计评估和网站性能改善；</li>
    <li>
      邀请码
      Cookie，以识别我们的合作伙伴推荐给本网站的客户，以便我们可以向推荐人授予佣金；
    </li>
    <li>Cookies，用于为用户个性化网站内容，例如设置默认语言。</li>
  </ul>
  <p>
    您可以在网站每次在浏览器中放置 cookie 时设置警告，也可以选择禁用所有
    cookie。您可以同时通过浏览器设置进行操作。由于每种浏览器都有不同的 cookie
    管理程序，请查看浏览器的“帮助”菜单以了解正确的方法。
  </p>
  <p>
    另外，您也可以访问“网络广告主动退出”页面或使用 Google
    Analytics（分析）退出浏览器插件来禁用所有 cookie 。请注意，选择禁用 cookie
    可能会对某些功能产生负面影响，这些功能会使您的网站体验更加高效。
  </p>
  <h2>第三方网站</h2>
  <p>
    GoDuck VPN
    的网站可能包含指向其他第三方网站的链接。请了解并理解，我们不控制他们如何操作其隐私惯例，我们对此不承担任何责任。
  </p>
  <h2>同意和年龄限制</h2>
  <p>
    使用网站、内容、应用程序、软件或服务，即表示您同意按照我们的服务条款和隐私政策中所述处理您的信息。
  </p>
  <p>
    该服务适用于18岁以上的成年人。如果您认为您的孩子已经向我们提供了信息，请立即告知我们。
  </p>
  <h2>适用法律</h2>
  <p>
    使您的信息保密是我们的核心使命。为了履行这一使命，GoDuck network booster
    的注册营业地点是在新加坡，新加坡拥有完整的数据保护法规，GoDuck network
    booster 遵守服务器所在地的法律或执法机构的要求。
  </p>
  <p>请务必注意，GoDuck VPN 不会收集任何 IP 地址、浏览历史记录、流量数据等。</p>
  <h2>欧盟用户</h2>
  <p>
    GoDuck VPN
    致力于在全球范围内保护用户隐私，我们现有的做法通过最小化数据收集并确保用户可以控制其个人信息来体现这一点。欧盟（EU）的通用数据保护法规（GDPR）要求我们以特定方式为欧盟用户概述这些做法。
  </p>
  <p>
    根据 GDPR 的规定，我们会根据以下情况之一收集和处理本隐私政策中概述的数据：
  </p>
  <ol>
    <li>为了履行我们对用户的合同义务，包括：</li>
    <li>向用户提供他们所请求的服务和应用程序。</li>
    <li>管理用户订阅并处理付款。</li>
    <li>提供客户支持。</li>
    <li>为了与我们的业务运营相关的合法利益，包括：</li>
    <li>提高我们的网站，服务和应用程序的质量，可靠性和有效性。</li>
    <li>与客户沟通以提供信息并寻求与我们的服务和应用程序有关的反馈。</li>
    <li>经用户同意，该用户可以随时退出。</li>
  </ol>
  <p>
    您可以根据 GDPR
    行使您的权利，以访问、转移、更正、删除或反对处理您的个人信息，方法是通过
    <a style="color: #e8aa10" href="mailto:info@goduck.io"> info@goduck.io </a>
    与我们联系。
  </p>
  <h2>个人信息</h2>
  <p>
    Go
    Duck的研发与创新，不会用于与用户个人信息相关的数据，包括但不限于用户名昵称，电子邮件，包括您的隐私设置，以及您用于管理我们的VPN服务的目的，所使用的产品和功能。除了有限的例外情况，我们不会从您那里收集任何个人信息。
  </p>
  <p>
    当您首次启用我们的APP，希望得到VPN服务时，APP会为您分配一个唯一的服务器标识符（这种唯一标识符只与您的设备识别码绑定，但不会与用户的任何其他个人信息相关联）。当您使用VPN服务时，我们将通过此唯一标识符检查您的帐户信息，以确定您在建立VPN连接之前注册的服务级别。
  </p>
  <p>
    如果您直接与我们联系，我们可能会收到有关您的其他信息，例如您的姓名，电子邮件地址，邮件内容，您可能发送给我们的附件，以及您可能选择提供的任何其他信息。
  </p>
  <p>
    我们可能会使用您的电子邮件地址（如果有的话，取决于您的选择），原因如下：
  </p>
  <ul>
    <li>与您沟通有关VPN服务或回复您的通信；</li>
    <li>向您发送更新和公告；</li>
    <li>
      发送营销信息，例如优惠，邀请以及相关的其他事项。未经您事先同意，我们不会将您的个人信息出售给第三方。
    </li>
  </ul>
  <h2>与您的VPN使用相关的服务信息</h2>
  <p>
    根据适用法律，和避免侵犯任何互联网服务提供商，任何浏览信息，流量目的地，数据内容，IP地址，DNS查询或与您在我们的服务器上相关的其他类似信息，都会被加密，并在关闭VPN“会话”之后清除。也就是说，我们不会收集有关您访问的网站，或存储在您设备上，或从您的设备传输的任何数据的任何信息，包括您设备上的应用程序可能通过VPN网络传输的任何数据。更具体地说：
  </p>
  <ul>
    <li>我们不知道哪个用户访问过特定网站或服务；</li>
    <li>我们不知道哪个用户在特定时间连接到VPN；</li>
    <li>我们不知道用户计算机的原始IP地址集或临时分配的网络地址。</li>
  </ul>
  <p>
    如果有人想访问或尝试强制获取基于上述任何一项的用户信息，我们无法提供此信息，因为数据不存在。
  </p>
  <p>
    为了真诚履行我们的合同义务并使您享受优质的VPN服务，我们收集了与您的VPN使用相关的以下信息:
  </p>
  <p>与APP和APP版本相关的信息</p>
  <p>
    我们收集APP和APP版本相关的信息。它使我们的支持团队能够有效地找到并消除技术问题。
  </p>
  <p>与VPN连接的相关信息</p>
  <p>
    我们收集有关VPN连接是否成功的信息。最小的信息使我们能够为您提供有效的技术支持，例如识别连接问题，提供有关如何使用我们的VPN服务的国家特定建议，以及使我们的工程师能够识别和修复网络问题。
  </p>
  <p>网络诊断，研究和分析的匿名数据</p>
  <p>
    当您成为VPN服务的用户之一时，经过您的许可，我当您成为VPN服务的用户之一时，经过您的许可，我们会在网络运营工具中收集用于网络诊断的最小使用统计数据和匿名分析数据，以优化网络速度。学习和分析统计数据使我们能够提高服务质量和产品质量。
  </p>
  <p>
    我们确保收到的信息完全匿名，不能与个人用户关联（即我们不存储哪个用户发送了哪些数据，我们不存储IP地址）。
  </p>
  <p>我们将收集以下信息：</p>
  <ul>
    <li>
      个人数据。是指可单独使用或与其他数据结合使用以唯一识别个人身份的任何信息。收集个人数据的目的是为您提供我们的服务，其中可能包括您在创建或更新帐户时提交给我们的用户名、电子邮件地址和付款信息；
    </li>
    <li>
      设备信息。Go Duck VPN
      使用设备信息（包括设备类型、操作系统/语言或用户代理，以及 Android 或 iOS
      设备提供的移动标识符）来生成与用户了解和注册的营销渠道和广告合作伙伴相关的统计数据Go
      Duck VPN 应用程序。此设备信息不包含您的姓名、电子邮件地址或其他个人数据；
    </li>
    <li>
      VPN特定信息。我们的VPN产品将不会记录任何您的帐户凭据，身份或与您的VPN会话活动相关联的信息。我们不会保留任何显示您正在浏览哪些网站或通过VPN连接访问的应用程序的记录。但是，我们会：
      <ul>
        <li>记录VPN会话中使用的带宽量</li>
        <li>确定所用VPN服务器的位置</li>
        <li>收集与上述类似的诊断信息</li>
        <li>
          如果您对服务不满意，请您评价您的连接并提供一些更详细的调查信息。此信息与特定用户无关
        </li>
      </ul>
    </li>
    <li>有关VPN是否尝试连接以及如何失败的诊断信息；</li>
    <li>速度测试数据；</li>
    <li>
      崩溃报告，也没有任何个人身份信息。这些由第三方以匿名形式处理，具体取决于您使用的平台。在iOS上，Apple的崩溃报告可以在iOS设置中关闭。
    </li>
  </ul>
  <p>结论</p>
  <p>
    我们收集最少的使用统计数据以维持我们的服务质量。虽然我们为用户提供服务以提高网络速度，但我们无法唯一地识别任何用户的任何特定行为。
  </p>
  <p>我们坚持对客户隐私的坚定承诺，不拥有与用户在线行为相关的任何数据。</p>
  <h2>信息的使用和共享</h2>
  <p>我们如何使用IP地址一一一一</p>
  <p>
    如上所述，我们不会记录或以其他方式记录与您的VPN浏览活动相关的IP地址。为了信息透明起见，我们会在这些其他情况下收集和使用IP地址：
  </p>
  <ul>
    <li>防止与我们的金融交易有关的欺诈；</li>
    <li>
      获取不可识别的信息项，例如您的大致地理位置和有关您的互联网服务提供商或运营商的信息（我们使用此信息来提供更好的用户体验，例如通过将您连接到离您较近的VPN服务器并通过分析与不同互联网服务提供商的连接质量）；
    </li>
    <li>
      使我们能够将我们的广告和服务功能归因于为响应此类广告和服务功能而采取的行动。
    </li>
  </ul>
  <p>我们如何使用信息一一一一</p>
  <p>我们以以下方式使用我们收集的信息，包括:</p>
  <ul>
    <li>提供，运营和维护我们的服务；</li>
    <li>改进，个性化和扩展我们的服务；</li>
    <li>了解并分析您如何使用我们的服务；</li>
    <li>开发新产品，服务，和功能；</li>
    <li>处理您的交易；</li>
    <li>查找并防止欺诈。</li>
  </ul>
  <p>我们如何分享信息一一一一</p>
  <p>我们可能会以以下方式分享我们收集的信息，包括:</p>
  <p>
    您通过推荐注册时分享的信息。如果您通过朋友推荐来注册我们的服务，我们可能会与您的推荐人分享信息，让他们知道您使用他们的推荐来注册我们的服务。
  </p>
  <p>
    分析。我们在网络运营工具中收集用于网络诊断的最小使用统计数据和匿名分析数据。请参阅本隐私政策第2部分中的更多信息。
  </p>
  <p>
    业务转移。作为任何拟议的合并，收购，债务融资，资产出售或类似交易的一部分，或者在破产，或接收信息的情况下，信息可能会被披露并以其他方式转让给任何潜在的收购方，继承人或受让人。转让给一个或多个第三方作为我们的业务资产之一。
  </p>
  <h2>隐私政策的变更</h2>
  <p>
    根据适用的隐私法律和原则，我们可能会不时更改隐私政策，恕不另行通知。您继续使用本网站或服务即表示您接受我们的隐私政策。
  </p>
  <h2>联系我们</h2>
  <p>
    如果您对我们的隐私政策以及我们如何处理您的信息有任何疑问，请随时通过以下电子邮件地址与我们联系：<a
      style="color: #e8aa10"
      href="mailto:info@goduck.io"
      >info@goduck.io</a
    >
  </p>
</template>
