import { api } from 'src/boot/axios'
import { useUserStore } from 'src/stores/user'

export function sendEmailCodeReq(data) {
  return api({
    url: 'message/getCode',
    method: 'post',
    headers: {
      DeviceId: useUserStore().userLoginInfo.deviceId
    },
    data: {
      Email: data.email
    }
  })
}

export function bindEmailReq(data) {
  return api({
    url: 'message/bindMail',
    method: 'post',
    headers: {
      DeviceId: useUserStore().userLoginInfo.deviceId
    },
    data: {
      Email: data.email,
      Code: data.code
    }
  })
}

export function sendPasswordCodeReq(data) {
  return api({
    url: 'message/forgetPassword',
    method: 'post',
    data: {
      Email: data.email
    }
  })
}
