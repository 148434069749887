import { api } from 'src/boot/axios'
import { useUserStore } from 'stores/user'

function getCommissionTotalReq() {
  return api({
    url: 'user/getCommission',
    method: 'post',
    headers: {
      DeviceId: useUserStore().userLoginInfo.deviceId
    },
    data: {}
  })
}

function getCommissionWithdrawnReq() {
  return api({
    url: 'commission/totalAmount',
    method: 'post',
    headers: {
      DeviceId: useUserStore().userLoginInfo.deviceId
    },
    data: {}
  })
}

export function getCommissionReq() {
  return Promise.allSettled([
    getCommissionTotalReq(),
    getCommissionWithdrawnReq()
  ]).then(([res0, res1]) => {
    const total = res0.value.data?.commission ?? 0
    const withdrawn = res1.value.data?.Total ?? 0
    const notYetWithdrawn = total - withdrawn

    return {
      total,
      withdrawn,
      notYetWithdrawn
    }
  })
}

export function getCommissionRecordReq(pageNum = 1, pageSize = 10) {
  return api({
    url: 'commission/record',
    method: 'post',
    headers: {
      DeviceId: useUserStore().userLoginInfo.deviceId
    },
    data: {
      pageNum,
      pageSize
    }
  })
}
