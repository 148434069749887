<template>
  <section
    v-if="!hideBanner"
    class="u-relative u-mt-[130px] u-h-[70px] sm:u-h-[162px] u-bg-[#F7F7F7]">
    <div
      class="u-absolute -u-top-[130px] u-w-full u-h-[200px] u-flex u-flex-col u-items-center u-justify-center sm:u-h-[292px]">
      <div
        class="u-px-[clamp(0rem,-5.25rem+13.125vw,10.5rem)] u-w-full u-h-full u-absolute u-top-0 u-left-0">
        <img
          alt="footer-bg"
          loading="lazy"
          src="/img/help-yellow-banner.webp"
          srcset="
            /img/help-yellow-banner@1x.webp 1x,
            /img/help-yellow-banner@2x.webp 2x,
            /img/help-yellow-banner@3x.webp 3x
          "
          class="u-w-full u-h-full u-object-cover u-rounded-[10px]" />
      </div>
      <p
        class="u-m-0 u-z-10 u-whitespace-pre-line u-text-center u-font-bold u-text-[26px] u-leading-[34px] u-text-[#1c1c1c] sm:u-text-[46px] sm:u-leading-[52px]">
        {{ $t('footer.footerBanner.title.0') }}<br />
        {{ $t('footer.footerBanner.title.1') }}
      </p>
      <div
        class="u-mt-[16px] u-mb-3 u-min-w-[300px] u-grid u-grid-cols-2 u-gap-3 sm:u-mt-[46px] sm:u-min-w-[452px] sm:u-gap-5 sm:u-justify-start">
        <q-btn
          :label="$t('footer.footerBanner.purchase')"
          :to="'/pricing?lang=' + userStore.lang"
          rounded
          flat
          class="u-min-h-10 u-px-4 u-bg-[#FCB400] u-font-bold u-text-[16px] u-leading-[36px] sm:u-px-6 sm:u-h-[50px] sm:u-text-[20px]" />
        <q-btn
          :label="$t('footer.footerBanner.download')"
          :href="platformVersion.url"
          outline
          rounded
          class="u-min-h-10 u-px-4 u-text-[#FCB400] u-font-bold u-text-[16px] u-leading-[36px] sm:u-px-6 sm:u-h-[50px] sm:u-text-[20px]" />
      </div>
    </div>
  </section>
  <q-footer
    reveal
    class="u-relative u-p-[40px_0_40px_0] sm:u-p-[48px_0_30px_0] u-bg-[#F7F7F7]">
    <div class="u-max-w-[1149px] u-m-auto u-px-[29px] xl:u-px-0">
      <div
        v-if="screen.width >= 640 && screen.width < 1024"
        class="u-mb-12 u-flex u-justify-center u-items-center">
        <img
          src="/img/GoDuckVPNLogo.webp"
          alt="GoDuckLogo"
          loading="lazy"
          class="u-object-cover u-w-[194px] u-h-[56px]"
          srcset="
            /img/GoDuckVPNLogo-sm@1x.webp 1x,
            /img/GoDuckVPNLogo-sm@2x.webp 2x,
            /img/GoDuckVPNLogo-sm@3x.webp 3x
          " />
      </div>
      <div v-if="screen.width >= 640" class="u-flex u-justify-between">
        <img
          v-if="screen.width >= 1024"
          src="/img/GoDuckVPNLogo.webp"
          alt="GoDuckLogo"
          loading="lazy"
          class="u-object-cover u-w-[194px] u-h-[56px]"
          srcset="
            /img/GoDuckVPNLogo-sm@1x.webp 1x,
            /img/GoDuckVPNLogo-sm@2x.webp 2x,
            /img/GoDuckVPNLogo-sm@3x.webp 3x
          " />
        <div v-for="(item, index) in footerContent" :key="index">
          <p
            class="u-mb-2 u-text-[#1C1C1C] u-font-medium u-text-[16px] u-leading-[22px]">
            {{ item.title }}
          </p>
          <div
            v-for="(content, contentIndex) in item.content"
            :key="contentIndex"
            class="u-min-h-9 u-flex u-items-center">
            <a
              v-if="content.isExternal && !!content.link"
              :href="content.link"
              class="u-text-[#666666] u-text-sm u-no-underline">
              {{ content.title }}
            </a>
            <router-link
              v-else-if="!content.isExternal && !!content.link"
              :to="content.link"
              class="u-text-[#666666] u-text-sm u-no-underline">
              {{ content.title }}
            </router-link>
            <PolicyStatementButton
              v-else-if="content.id === 'footer-privacy'"
              name="PrivacyPolicy"
              class="u-text-[#666666] u-text-sm u-no-underline">
              {{ content.title }}
            </PolicyStatementButton>
            <PolicyStatementButton
              v-else-if="content.id === 'footer-terms'"
              name="TermOfService"
              class="u-text-[#666666] u-text-sm u-no-underline">
              {{ content.title }}
            </PolicyStatementButton>
            <span v-else class="u-text-[#666666] u-text-sm u-no-underline">
              {{ content.title }}
            </span>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="u-mb-[10px] u-flex u-justify-between u-items-center">
          <img
            src="/img/GoDuckVPNLogo.webp"
            alt="GoDuckLogo"
            loading="lazy"
            class="u-object-cover u-w-[102px] u-h-[30px]"
            srcset="
              /img/GoDuckVPNLogo-xs@1x.webp 1x,
              /img/GoDuckVPNLogo-xs@2x.webp 2x,
              /img/GoDuckVPNLogo-xs@3x.webp 3x
            " />
          <q-btn
            :label="$t('header.language')"
            @mouseenter="onLangMenu({ isBtnIn: true })"
            @mouseleave="onLangMenu({ isBtnIn: false })"
            flat
            icon="img:icons/language.svg"
            :icon-right="matArrowDropDown"
            class="u-p-0 u-min-h-5 u-text-[#666666] u-text-xs u-leading-5 [&_.q-icon]:u-text-[125%] [&_.q-icon.on-left]:u-mr-2">
            <q-menu
              :model-value="langMenu.visible"
              @update:model-value="(visible) => onLangMenu({ visible })"
              @mouseenter="onLangMenu({ isMenuIn: true })"
              @mouseleave="onLangMenu({ isMenuIn: false })"
              fit>
              <q-list>
                <q-item clickable v-on:click="changeLang('zh-CN')">
                  <q-item-section>简体中文</q-item-section>
                </q-item>
                <q-item clickable v-on:click="changeLang('zh-TW')">
                  <q-item-section>繁体中文</q-item-section>
                </q-item>
                <q-item clickable v-on:click="changeLang('en-US')">
                  <q-item-section>English</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
        <q-expansion-item
          header-class="u-p-0 u-min-h-9 u-items-center u-text-[#1C1C1C] u-font-medium u-text-xs u-no-underline"
          v-for="(item, index) in footerContent"
          :key="index"
          :label="item.title">
          <q-list separator>
            <q-item
              v-for="(content, contentIndex) in item.content"
              :key="contentIndex"
              clickable
              v-ripple
              class="u-p-0 u-min-h-9 u-items-center">
              <a
                v-if="content.isExternal && !!content.link"
                :href="content.link"
                class="u-text-[#666666] u-text-xs u-no-underline">
                {{ content.title }}
              </a>
              <router-link
                v-else-if="!content.isExternal && !!content.link"
                :to="content.link"
                class="u-text-[#666666] u-text-xs u-no-underline">
                {{ content.title }}
              </router-link>
              <PolicyStatementButton
                v-else-if="content.id === 'footer-privacy'"
                name="PrivacyPolicy"
                class="u-w-full u-text-[#666666] u-text-xs u-no-underline">
                {{ content.title }}
              </PolicyStatementButton>
              <PolicyStatementButton
                v-else-if="content.id === 'footer-terms'"
                name="TermOfService"
                class="u-w-full u-text-[#666666] u-text-xs u-no-underline">
                {{ content.title }}
              </PolicyStatementButton>
              <span v-else class="u-text-[#666666] u-text-xs u-no-underline">
                {{ content.title }}
              </span>
            </q-item>
          </q-list>
        </q-expansion-item>
      </div>
    </div>
    <div>
      <PolicyStatementButton
        :name="queryPolicy"
        class="u-w-full u-text-[#666666] u-text-xs u-no-underline" />
    </div>
    <div class="u-my-5 u-px-[29px] sm:u-my-7 sm:u-px-0">
      <div class="divider-line" />
    </div>
    <div
      class="u-max-w-[1149px] u-m-auto u-px-[29px] xl:u-px-0 u-flex u-justify-between u-items-center">
      <p
        class="u-m-0 u-text-[#666666] u-text-xs sm:u-text-[16px] sm:u-leading-[22px]">
        {{ $t('footer.copyright') }}
      </p>
      <div class="u-flex u-gap-x-2 u-items-center sm:u-gap-x-4">
        <q-img
          v-for="(item, index) in payments"
          :key="index"
          :src="require(`src/assets/${item.name}.svg`)"
          fit="contain"
          class="u-w-7 u-h-5" />
        <q-btn
          v-if="screen.width >= 640"
          :label="$t('header.language')"
          @mouseenter="onLangMenu({ isBtnIn: true })"
          @mouseleave="onLangMenu({ isBtnIn: false })"
          flat
          icon="img:icons/language.svg"
          :icon-right="matArrowDropDown"
          class="u-ml-2 u-p-0 u-min-h-5 u-text-[#666666] u-text-base u-leading-5 [&_.q-icon]:u-text-[125%] [&_.q-icon.on-left]:u-mr-2">
          <q-menu
            :model-value="langMenu.visible"
            @update:model-value="(visible) => onLangMenu({ visible })"
            @mouseenter="onLangMenu({ isMenuIn: true })"
            @mouseleave="onLangMenu({ isMenuIn: false })"
            fit>
            <q-list>
              <q-item clickable v-on:click="changeLang('zh-CN')">
                <q-item-section>简体中文</q-item-section>
              </q-item>
              <q-item clickable v-on:click="changeLang('zh-TW')">
                <q-item-section>繁体中文</q-item-section>
              </q-item>
              <q-item clickable v-on:click="changeLang('en-US')">
                <q-item-section>English</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
    </div>
  </q-footer>
</template>

<script>
import { debounce, useQuasar } from 'quasar'
import { matArrowDropDown } from '@quasar/extras/material-icons'
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useUserStore } from 'src/stores/user'
import usePlatformVersion from 'src/stores/platform-version'
import PolicyStatementButton from './PolicyStatementButton.vue'

export default {
  name: 'AppFooter',
  components: { PolicyStatementButton },
  props: {
    hideBanner: Boolean
  },
  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const { screen } = useQuasar()
    const { locale } = useI18n()
    const userStore = useUserStore()
    const platformVersion = usePlatformVersion()

    const changeLang = (lang) => {
      userStore.setLang(lang)
      locale.value = lang
      router.push({
        query: { ...route.query, lang }
      })
    }

    // make Menu clickable and hoverable
    const langMenu = reactive({
      isBtnIn: false,
      isMenuIn: false,
      visible: false
    })
    const onLangMenu = debounce(
      (
        value = {
          visible: undefined,
          isBtnIn: langMenu.isBtnIn,
          isMenuIn: langMenu.isMenuIn
        }
      ) => {
        if (typeof value.visible === 'boolean') {
          langMenu.isBtnIn = false
          langMenu.isMenuIn = false
          langMenu.visible = value.visible
        } else {
          langMenu.isBtnIn = value.isBtnIn
          langMenu.isMenuIn = value.isMenuIn
          langMenu.visible = value.isBtnIn || value.isMenuIn
        }
      },
      100
    )

    return {
      matArrowDropDown,
      screen,
      queryPolicy: route.query.p,
      userStore,
      platformVersion,
      changeLang,
      langMenu,
      onLangMenu,
      footerContent: [
        {
          id: 'footer-aboutUs',
          title: t('footer.aboutUs.title'),
          content: [
            {
              id: 'footer-privacy',
              title: t('footer.aboutUs.privacy')
            },
            {
              id: 'footer-terms',
              title: t('footer.aboutUs.terms')
            },
            {
              id: 'footer-freeTrial',
              title: t('footer.aboutUs.freeTrial'),
              link: '/help/article/freevpn?lang=' + userStore.lang
            }
          ]
        },
        {
          id: 'footer-application',
          title: t('footer.application.title'),
          content: [
            {
              id: 'footer-ios',
              title: t('footer.application.ios'),
              link: '/download?platform=iOS&lang=' + userStore.lang
            },
            {
              id: 'footer-mac',
              title: t('footer.application.mac'),
              link: '/download?platform=macOS&lang=' + userStore.lang
            },
            {
              id: 'footer-android',
              title: t('footer.application.android'),
              link: '/download?platform=Android&lang=' + userStore.lang
            },
            {
              id: 'footer-windows',
              title: t('footer.application.windows'),
              link: '/download?platform=Windows&lang=' + userStore.lang
            }
          ]
        },
        {
          id: 'footer-helpCenter',
          title: t('footer.helpCenter.title'),
          content: [
            {
              id: 'footer-faq',
              title: t('footer.helpCenter.faq'),
              link: '/faq?lang=' + userStore.lang
            },
            {
              id: 'footer-tutorial',
              title: t('footer.helpCenter.tutorial'),
              link: '/blog?lang=' + userStore.lang + '&category=tutorial'
            },
            {
              id: 'footer-announcement',
              title: t('footer.helpCenter.announcement'),
              link: '/blog?lang=' + userStore.lang + '&category=announcement'
            },
            {
              id: 'footer-contact',
              title: t('footer.helpCenter.contact'),
              link: 'mailto:info@goduck.io',
              isExternal: true
            }
          ]
        },
        {
          id: 'footer-followUs',
          title: t('footer.followUs.title'),
          content: [
            {
              id: 'footer-facebook',
              title: t('footer.followUs.facebook'),
              link: 'https://www.facebook.com/goduckvpn',
              isExternal: true
            },
            {
              id: 'footer-twitter',
              title: t('footer.followUs.twitter'),
              link: 'https://twitter.com/GoDuckVPN',
              isExternal: true
            },
            {
              id: 'footer-youtube',
              title: t('footer.followUs.youtube'),
              link: 'https://www.youtube.com/channel/UCm028esOyZlH16P-xwKc_-Q',
              isExternal: true
            },
            {
              id: 'footer-instagram',
              title: t('footer.followUs.instagram'),
              link: 'https://www.instagram.com/goduckvpn/',
              isExternal: true
            }
          ]
        },
        {
          id: 'footer-collaborate',
          title: t('footer.collaborate.title'),
          content: [
            {
              id: 'footer-partner',
              title: t('footer.collaborate.partner'),
              link: '/my-affiliate-program?lang=' + userStore.lang
            },
            {
              id: 'footer-invitation',
              title: t('footer.collaborate.invitation'),
              link: '/promote?lang=' + userStore.lang
            }
          ]
        }
      ],
      payments: [
        { name: 'visa', enable: true },
        { name: 'mastercard', enable: true },
        { name: 'unionpay', enable: true }
      ].filter((item) => item.enable)
    }
  }
}
</script>

<style scoped>
.divider-line {
  border: 0.5px solid var(--divider-line);
  width: 100%;
}
</style>
