<template>
  <div class="u-inline u-cursor-pointer" v-bind="$attrs" @click="open">
    <slot />
  </div>
  <q-dialog v-model="isDialogActived" class="large">
    <q-card class="u-flex u-flex-col">
      <q-card-section
        class="q-dialog__message u-flex-1 u-text-left u-overflow-auto">
        <h1>{{ $t('myAffiliateProgram.prizeRuleDialog.title') }}</h1>
        <p>
          <b>{{ $t('myAffiliateProgram.prizeRuleDialog.content.0.title') }}</b>
          <span>{{
            $t('myAffiliateProgram.prizeRuleDialog.content.0.desc.0')
          }}</span>
        </p>
        <p>
          <b>{{ $t('myAffiliateProgram.prizeRuleDialog.content.1.title') }}</b>
          <span>{{
            $t('myAffiliateProgram.prizeRuleDialog.content.1.desc.0')
          }}</span>
        </p>
        <p>
          <b>{{ $t('myAffiliateProgram.prizeRuleDialog.content.2.title') }}</b>
        </p>
        <ul>
          <li>
            {{ $t('myAffiliateProgram.prizeRuleDialog.content.2.desc.0') }}
          </li>
          <li>
            {{ $t('myAffiliateProgram.prizeRuleDialog.content.2.desc.1') }}
          </li>
        </ul>
        <p>
          <b>{{ $t('myAffiliateProgram.prizeRuleDialog.content.3.title') }}</b>
          <span>{{
            $t('myAffiliateProgram.prizeRuleDialog.content.3.desc.0')
          }}</span>
        </p>
        <p>
          <b>{{ $t('myAffiliateProgram.prizeRuleDialog.content.4.title') }}</b>
          <span>{{
            $t('myAffiliateProgram.prizeRuleDialog.content.4.desc.0')
          }}</span>
        </p>
        <ul>
          <li>
            {{ $t('myAffiliateProgram.prizeRuleDialog.content.4.desc.1') }}
          </li>
          <li>
            {{ $t('myAffiliateProgram.prizeRuleDialog.content.4.desc.2') }}
          </li>
          <li>
            {{ $t('myAffiliateProgram.prizeRuleDialog.content.4.desc.3') }}
          </li>
          <li>
            {{ $t('myAffiliateProgram.prizeRuleDialog.content.4.desc.4') }}
          </li>
        </ul>
        <p>
          <b>{{ $t('myAffiliateProgram.prizeRuleDialog.content.5.title') }}</b>
          <span>{{
            $t('myAffiliateProgram.prizeRuleDialog.content.5.desc.0')
          }}</span>
        </p>
        <p>
          <b>{{ $t('myAffiliateProgram.prizeRuleDialog.content.6.title') }}</b>
          <span>{{
            $t('myAffiliateProgram.prizeRuleDialog.content.6.desc.0')
          }}</span>
        </p>
        <p>
          <b>{{ $t('myAffiliateProgram.prizeRuleDialog.content.7.title') }}</b>
          <span>{{
            $t('myAffiliateProgram.prizeRuleDialog.content.7.desc.0')
          }}</span>
        </p>
        <p>
          <b>{{ $t('myAffiliateProgram.prizeRuleDialog.content.8.title') }}</b>
          <span>{{
            $t('myAffiliateProgram.prizeRuleDialog.content.8.desc.0')
          }}</span>
        </p>
        <p>
          <b>{{ $t('myAffiliateProgram.prizeRuleDialog.content.9.title') }}</b>
          <span>{{
            $t('myAffiliateProgram.prizeRuleDialog.content.9.desc.0')
          }}</span>
        </p>
        <p>
          <b>{{ $t('myAffiliateProgram.prizeRuleDialog.content.10.title') }}</b>
          <span>{{
            $t('myAffiliateProgram.prizeRuleDialog.content.10.desc.0')
          }}</span>
        </p>
      </q-card-section>
      <q-card-actions class="u-flex-none">
        <q-btn flat :label="$t('global.close')" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup() {
    const isDialogActived = ref(false)

    const open = () => {
      isDialogActived.value = true
    }

    return {
      isDialogActived,
      open
    }
  }
})
</script>

<style lang="scss" scoped>
:deep() {
  h1 {
    margin-top: 1em;
    margin-bottom: 29px;
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #1c1c1c;
  }
  h1 sub {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
  }
  h2 {
    margin: 1em 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #1c1c1c;
  }
  p {
    font-size: 14px;
    margin: 1em 0;
  }
  ul,
  ol {
    font-size: 14px;
    margin: 1em 0;
    padding-inline-start: 1.8em;
  }
  b {
    font-weight: 500;
  }

  @media screen and (min-width: 640px) {
    h1 {
      font-size: 36px;
      line-height: 32px;
    }
    h1 sub {
      font-size: 16px;
      line-height: 20px;
    }
    h2 {
      font-size: 20px;
      line-height: 24px;
    }
    p {
      font-size: 16px;
    }
    ul,
    ol {
      font-size: 16px;
    }
  }
}
</style>
