import { client } from 'app/sanity/lib/client'
import {
  CATEGORIES_QUERY,
  FAQ_CATEGORIES_QUERY,
  FAQS_COUNT_QUERY,
  FAQS_QUERY,
  ARTICLE_QUERY,
  ARTICLES_COUNT_QUERY,
  ARTICLES_QUERY,
  SIMILAR_ARTICLES_QUERY,
  REVIEWS_QUERY
} from 'app/sanity/lib/query'

/**
 * @typedef { import("./cms").CategoriesQueryParams } CategoriesQueryParams
 * @typedef { import("./cms").CategoriesQueryResult } CategoriesQueryResult
 * @typedef { import("./cms").FaqCategoriesQueryParams } FaqCategoriesQueryParams
 * @typedef { import("./cms").FaqCategoriesQueryResult } FaqCategoriesQueryResult
 * @typedef { import("./cms").FaqsQueryParams } FaqsQueryParams
 * @typedef { import("./cms").FaqsQueryResult } FaqsQueryResult
 * @typedef { import("./cms").PostsQueryParams } PostsQueryParams
 * @typedef { import("./cms").PostsQueryResult } PostsQueryResult
 * @typedef { import("./cms").PostQueryParams } PostQueryParams
 * @typedef { import("./cms").Post } Post
 * @typedef { import("./cms").SimilarPostsQueryParams } SimilarPostsQueryParams
 * @typedef { import("./cms").SimilarPostsQueryResult } SimilarPostsQueryResult
 * @typedef { import("./cms").ReviewsResult } ReviewsResult
 */

/**
 * @param {CategoriesQueryParams} params
 * @returns {Promise<CategoriesQueryResult>}
 */
export const getCategories = async ({ lng }) => {
  const list = await client.fetch(CATEGORIES_QUERY, {
    lng
  })

  return { list }
}

/**
 * @param {PostsQueryParams} params
 * @returns {Promise<PostsQueryResult>}
 */
export const getPosts = async (params) => {
  const {
    lng,
    pageSize = 10,
    pageIndex = 1,
    category = null,
    keywords = ''
  } = params
  const pData = client.fetch(
    ARTICLES_QUERY,
    {
      lng,
      limit: pageSize,
      offset: (pageIndex - 1) * pageSize,
      category: category || null,
      keywords: keywords || null
    },
    {
      next: { tags: ['post'] }
    }
  )
  const pCount = client.fetch(
    ARTICLES_COUNT_QUERY,
    {
      lng,
      category: category || null,
      keywords: keywords || null
    },
    {
      next: { tags: ['post'] }
    }
  )

  const [list, count] = await Promise.all([pData, pCount])

  const nextPageParam =
    count && count > (pageIndex - 1) * pageSize + list.length
      ? { ...params, pageIndex: pageIndex + 1 }
      : null

  return { nextPageParam, list, count }
}

/**
 * @param {PostQueryParams} params
 * @returns {Promise<Post>}
 */
export const getPost = async ({ lng, slug }) => {
  const data = await client.fetch(
    ARTICLE_QUERY,
    {
      lng,
      slug
    },
    {
      next: { tags: ['post'] }
    }
  )

  return data
}

/**
 * @param {SimilarPostsQueryParams} params
 * @returns {Promise<SimilarPostsQueryResult>}
 */
export const getSimilarPosts = async ({ lng, slug }) => {
  const list = await client.fetch(
    SIMILAR_ARTICLES_QUERY,
    {
      lng,
      slug
    },
    {
      next: { tags: ['post'] }
    }
  )

  return { list }
}

/**
 * @param {FaqCategoriesQueryParams} params
 * @returns {Promise<FaqCategoriesQueryResult>}
 */
export const getFaqCategories = async ({ lng }) => {
  const list = await client.fetch(FAQ_CATEGORIES_QUERY, {
    lng
  })

  return { list }
}

/**
 * @param {FaqsQueryParams} params
 * @returns {Promise<FaqsQueryResult>}
 */
export const getFaqs = async (params) => {
  const {
    lng,
    pageSize = 10,
    pageIndex = 1,
    category = null,
    keywords = ''
  } = params
  const pData = client.fetch(
    FAQS_QUERY,
    {
      lng,
      limit: pageSize,
      offset: (pageIndex - 1) * pageSize,
      category: category || null,
      keywords: keywords || null
    },
    {
      // TODO Sanity GROQ tags
      next: { tags: ['faq'] }
    }
  )
  const pCount = client.fetch(
    FAQS_COUNT_QUERY,
    {
      lng,
      category: category || null,
      keywords: keywords || null
    },
    {
      next: { tags: ['faq'] }
    }
  )

  const [list, count] = await Promise.all([pData, pCount])

  const nextPageParam =
    count && count > (pageIndex - 1) * pageSize + list.length
      ? { ...params, pageIndex: pageIndex + 1 }
      : null

  return { nextPageParam, list, count }
}

/**
 * @returns {Promise<ReviewsQueryResult>}
 */
export const getReviews = async () => {
  const list = await client.fetch(REVIEWS_QUERY)

  return { list }
}
