import moment from 'moment'
import { useInterval } from 'quasar'
import { onUnmounted, reactive } from 'vue'

export function useCountdown() {
  const { registerInterval, removeInterval } = useInterval()
  const state = reactive({
    endTime: null,
    timeLeft: null,
    countdown(end) {
      state.endTime = moment(end)

      registerInterval(() => {
        const left = state.endTime.diff(moment(), 'seconds')
        if (left <= 0) {
          removeInterval()
          state.endTime = null
          state.timeLeft = 0
        } else {
          state.timeLeft = left
        }
      }, 500)
    }
  })

  onUnmounted(() => {
    removeInterval()
  })

  return state
}

/**
 * @param {Pick<import('vue-i18n').Composer, 'd' | 'locale'>} i18n
 * @returns {(datetime: any) => string}
 *
 * @example useDateTimeFormat(useI18n())('2020-02-24') // en-US: 24 FEB 2020, zh-CN: 2020年2月24日, zh-TW: 2020年2月24日
 */
export function useDateTimeFormat(i18n) {
  const displayLocaleMap = { 'en-US': 'en-UK' }
  // [vue-i18n DateTimeFormat option](https://vue-i18n.intlify.dev/api/injection.html#d-value-options)
  const d = (datetime) =>
    ({ string: true, number: true }[typeof datetime]
      ? i18n.d(datetime, {
          locale: displayLocaleMap[i18n.locale.value] ?? i18n.locale.value,
          dateStyle: 'medium'
        })
      : '')
  return d
}
