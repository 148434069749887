<template>
  <h1>
    User Agreement
    <br />
    <sub>(Last updated on Feb 10, 2022)</sub>
  </h1>
  <p>
    Use of this site signifies your agreement to the Terms of Use.GoDuckVPN
    reserves the right, at any time, to modify, revise or update the Terms of
    Use at any time by updating this posting. Customer confirms his or her
    acceptance of the modifications or updates by continuing to use the site.
  </p>
  <p>
    Before using this VPN or you may become a member of GoDuckVPN, you must read
    and accept all of the terms in, and linked to, this Terms of Use and the
    Privacy Policy. We strongly recommend that, as you read this Terms of Use,
    you also access and read the linked information.
  </p>
  <h2>Services</h2>
  <p>
    GoDuckVPN Services are transitory digital network communications services,
    which enhance internet security and privacy. At your inh2idual request and
    at your sole discretion we automatically provide unsupervised and
    non-monitored data conduit – virtual private network (VPN) connection – for
    your exclusive use, at your own risk and responsibility. You are solely and
    exclusively responsible for the use of the GoDuckVPN Services. GoDuckVPN
    will not be liable in any way or form for actions done by its users
    including criminal liability and civil liability for harm executed or not
    executed. GoDuckVPN liability is further limited by other provisions of
    these Terms.
  </p>
  <p>
    We grant you permission to use the GoDuckVPN Services subject to the
    restrictions set forth in these Terms. Your use of the GoDuckVPN Services is
    at your own risk. The GoDuckVPN Services may be modified, updated,
    interrupted or suspended at any time without notice or liability. We do not
    bear any liability for any harm or other adverse consequences to you, caused
    by this. GoDuckVPN, its owners, employees, agents and others that are
    involved with the GoDuckVPN services are not in any way or form liable for
    any harm of any kind executed or not executed, resulting from or arising
    through or from the use of any account registered with GoDuckVPN services.
  </p>
  <p>
    GoDuckVPN guarantees a 99.9% service uptime. In any event, GoDuckVPN shall
    not be liable for any loss, monetary or non-monetary harm beyond what the
    client paid to GoDuckVPN, and this shall not include attorney fees or court
    costs irrespective of any laws or statutes that prescribe otherwise.
  </p>
  <h2>Intellectual Property Rights</h2>
  <p>
    Subject to the terms and conditions of these Terms, we grant you a limited,
    non-exclusive, personal, non-transferable, non-sublicensable, license to:
  </p>
  <ul>
    <li>download and use a copy of the GoDuckVPN software;</li>
    <li>
      use the GoDuckVPN Services, including, without limitation, the products
      and services made available on or through the GoDuckVPN software or our
      website. No other right or license of any kind is granted to you hereunder
      with respect to the GoDuckVPN Services. The license provided herein is
      effective until terminated. This license automatically terminates if you
      fail to comply with these Terms.
    </li>
  </ul>
  <p>
    The GoDuckVPN Services, including, but not limited to, the GoDuckVPN
    software, mobile application and all other items, are owned and copyrighted
    by GoDuckVPN, and are protected worldwide. We retain all right, title and
    interest in and to the GoDuckVPN Services and any portion thereof,
    including, without limitation, all copyrights, trademarks, service marks,
    trade secrets and other intellectual property rights. You shall not take any
    action to jeopardize, limit or interfere in any manner with our ownership of
    and rights with respect to the GoDuckVPN Services. All rights are reserved
    unless otherwise noted.
  </p>
  <h2>Prohibited and Restricted Uses</h2>
  <p>
    Your access to and use of the GoDuckVPN Services is subject to these Terms
    and all applicable laws and regulations. We reserve the right, at any time,
    in our sole discretion, with or without notice, to terminate the accounts
    of, and block access to the GoDuckVPN Services to any users who infringe any
    applicable laws or these Terms.
  </p>
  <p>
    While using the GoDuckVPN Services you yourself agree not to, and agree not
    assist, encourage, or enable others to use the GoDuckVPN Services:
  </p>
  <ol>
    <li>
      for unlawful, illicit, illegal, criminal or fraudulent activities,
      including but not limited to port scanning, sending spam, sending opt-in
      email, scanning for open relays or open proxies, sending unsolicited
      e-mail or any version or type of email sent in vast quantities even if the
      email is routed through third-party servers, any pop-up launching, use of
      stolen credit cards, credit card fraud, financial fraud, cryptocurrency
      fraud, cloaking, extortion, blackmail, kidnapping, rape, murder, sale of
      stolen credit cards, sale of stolen goods, offer or sale of prohibited,
      military and dual use goods, offer or sale of controlled substances,
      identity theft, hacking, pharming, phishing, scraping in any form or
      scale, digital piracy, intellectual property infringements and other
      similar activities;
    </li>
    <li>
      to assault, interfere, gain unauthorized access, deny service in any way
      or form to any other network, computer or node through GoDuckVPN Services;
    </li>
    <li>
      to exploit children in any way, including audio, video, photography,
      digital content, etc.;
    </li>
    <li>
      to upload or distribute in any way files that contain viruses, worms,
      trojans, corrupted files, or any other similar software or programs that
      may damage the operation of another’s computer;
    </li>
    <li>
      to interfere with or attempt to interfere with the proper working of the
      GoDuckVPN Services, any transactions being offered in connection with the
      GoDuckVPN Services or any other activities conducted by us, disrupt our
      website or any networks connected to the GoDuckVPN Services, or bypass any
      measures we may use to prevent or restrict access to the GoDuckVPN
      Services;
    </li>
    <li>
      to exploit the GoDuckVPN Services in any unauthorized way whatsoever,
      including, but not limited to, by trespass or burdening network capacity;
    </li>
    <li>
      to use any robot, spider, scraper, or other automated means to access our
      website or GoDuckVPN Services for any purpose without our prior written
      permission;
    </li>
    <li>
      to collect or harvest personal information about other users of the
      GoDuckVPN Services;
    </li>
    <li>
      to collect or harvest personal data of inh2iduals, without legitimate
      interest or consent, or in violation of any applicable law;
    </li>
    <li>
      to take any action that imposes or may imposes or may impose an
      unreasonable or disproportionately large load on our infrastructure;
    </li>
    <li>
      to share any data or other content with any unreasonably large number of
      persons, including without limitation sending blast communications to a
      large number of recipients or sharing content with persons you do not know
      or who do not know you;
    </li>
    <li>
      to represent that you are the representative or agent of any of the
      FoDuckVPN Services, including any of its functionality;
    </li>
    <li>
      to incorporate the GoDuckVPN Services or any portion thereof into any
      other program or product;
    </li>
    <li>
      to copy or reproduce, in any form or by any means, any part of the
      GoDuckVPN Services;
    </li>
    <li>
      to modify, translate, reverse engineer, decompile, disassemble, or create
      any derivative works based on the GoDuckVPN Services, including any of its
      files or documentation, or any portion thereof, or determine or attempt to
      determine any source code, algorithms, methods or techniques embodied in
      the GoDuckVPN application or any portion thereof;
    </li>
    <li>
      to violate general ethic or moral norms, good customs and fair conduct
      norms;
    </li>
    <li>
      to violate any third party’s rights, including any breach of confidence,
      personal data, copyright or any other intellectual property or proprietary
      right;
    </li>
    <li>
      to threaten, stalk, harm, or harass others, or promote bigotry or
      discrimination;
    </li>
    <li>to attempt to manipulate the GoDuckVPN name, services or products;</li>
    <li>
      to solicit or harvest personal information from or communicate with
      minors;
    </li>
    <li>
      attempt to gain unauthorized access to the GoDuckVPN Services, user
      accounts, computer systems or networks connected to the GoDuckVPN Services
      through hacking, password mining, brute force or any other means;
    </li>
    <li>
      to use the GoDuckVPN Services for any military purpose, including cyber
      warfare, weapons development, design, manufacture or production of
      missiles, nuclear, chemical or biological weapons;
    </li>
    <li>to otherwise infringe or circumvent these Terms.</li>
  </ol>
  <p>
    In addition, for users who make use of the bandwidth to download for a long
    time, which makes the servers abnormal and affects other users, GoDuckVPN
    will limit their speed in order to protect IP from being abused.
  </p>
  <p>
    For the security of your account, non-customized accounts are limited to
    personal use. Please do not share your account with others. The loss caused
    by sharing to others will be borne by you.
  </p>
  <p>
    We reserve the right to refuse service, suspend accounts or limit access to
    the GoDuckVPN Services in our sole discretion. Such suspension or access
    limitation may be implemented by GoDuckVPN instantly and without any
    indication, notice or refund. We may suspend your account for clarification,
    investigation or request you to explain your actions and provide additional
    information. If your account has been suspended, you must contact us for
    further information. We may suspend your user account for a reasonable
    period of time before we terminate a user account permanently.
  </p>
  <p>
    We are under no obligation to enforce the Terms against you. We encourage
    you to let us know about the violation of these Terms by any GoDuckVPN
    users; however, in case of such violations we may take appropriate action at
    our sole discretion.
  </p>
  <h2>Disclaimer of Warranties</h2>
  <p>
    Reasonable efforts are taken to improve the accuracy and integrity of the
    GoDuckVPN Services, but we are not responsible for downtime, loss of data,
    corrupt data, service delay, mistakes, out-of-date information, or other
    errors. Notwithstanding any other provision of these Terms, we reserve the
    right to change, suspend, remove, or disable access to the GoDuckVPN
    Services, or any functionality comprising a part of the GoDuckVPN Services
    at any time without notice. In no event will we be liable for making these
    changes. As a registered user in good standing, you may be provided with
    limited service by GoDuckVPN. We do not warrant, and will not have any
    liability or responsibility for your use of GoDuckVPN Services or other the
    products or services we provide. We may also impose limits on the use of or
    access to the GoDuckVPN Services, for any reason and without notice or
    liability. Our website, products and services may be unavailable from time
    to time due to human, digital, mechanical, telecommunication, software, and
    other failures. We cannot predict or control when such downtime may occur
    and cannot control the duration of such downtime.
  </p>
  <p>
    We shall not be responsible or have any liability for any delay or failure
    to perform to the extent due to unforeseen circumstances or causes beyond
    its reasonable control, including, without limitation, failures of your
    telecommunication or internet service providers, Force Majeure, earthquakes,
    fires, floods, embargoes, labor disputes and strikes, riots, war, novelty of
    product manufacture or other unanticipated product development problems, and
    acts of civil and military authorities.
  </p>
  <h2>Choice of Law</h2>
  <p>
    This Agreement shall be governed by and construed in accordance with the
    laws of Singapore, excluding its rules governing conflicts of law.
  </p>
  <h2>Changes to the Terms of Service</h2>
  <p>
    We may change our Terms of Service from time to time, without prior notice
    to you, consistent with applicable privacy laws and principles. Your
    continued use of the Site or Services constitutes your acceptance of our
    Terms of Service.
  </p>
  <h2>How to Contact US</h2>
  <p>
    If you have any questions regarding our Terms of Service and how we handle
    your information, please feel free to contact us at the following email
    address:
    <a style="color: #e8aa10" href="mailto:info@goduck.io">info@goduck.io</a>
  </p>
</template>
