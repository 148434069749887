<template>
  <h1>
    Privacy Policy
    <br />
    <sub>(Last updated on Jan 26, 2023)</sub>
  </h1>

  <p>
    GoDuck VPN respect our user’s privacy and make every effort to protect the
    privacy of our Users. We want you to understand what information we collect,
    what we don’t collect, and how we collect, use, and store information. We do
    not collect logs of your activity, including no logging of browsing history,
    traffic destination, data content, or DNS queries. Also, we never store
    connection logs, meaning no logs of your IP address, connection timestamp,
    or session duration.
  </p>
  <h2>Acceptance</h2>
  <p>
    We are committed to protecting your privacy. Your understanding and
    compliance with GoDuck VPN's Privacy Policy will help us provide you with an
    efficient and satisfying service experience. Our Privacy Policy covers any
    personal information that GoDuck VPN obtains from you when you use the
    services provided on GoDuck VPN.
  </p>
  <p>
    Services provided on GoDuck VPN, you consent to the terms of our Privacy
    Policy, including our use of information that is collected or submitted as
    described in our Privacy Policy.
  </p>
  <h2>Information We Collect</h2>
  <p>
    When you use GoDuck VPN you can choose to link with email, but it is not
    necessary. It will not affect usage if you do not link with the email. We
    will store this information, but will not share it with any third parties
    except as necessary to provide the features of the Service.
  </p>
  <p>
    For example, We may use your information when analyzing user data with
    third-party platform tools. We will use your contact details to send you
    notifications about the Service and to respond to customer support requests.
    You may access and change your personal information by logging in to the
    GoDuck VPN.
  </p>
  <p>GoDuck VPN uses your email address for the following reasons:</p>
  <ul>
    <li>To provide links to our Site, including password reset emails;</li>
    <li>
      To communicate with you about your VPN services or respond to your
      communications;
    </li>
    <li>
      To send marketing information, You may choose to not receive Marketing
      emails by following the opt-out procedure described in these emails.
    </li>
  </ul>
  <p>
    GoDuck VPN uses your personal information only for the purposes listed in
    this Privacy Policy, and we do not sell your personal information to third
    parties.
  </p>
  <h2>International Data Transfers</h2>
  <p>
    Our service is spread globally, and as such, your data travels through our
    global servers, which might not or might be outside of your country of
    residence. We rely on some third-party service providers to enable us to
    provide you our services. Whenever we transfer your information, we take
    steps to protect it.
  </p>
  <p>
    You acknowledge and understand that your information will be Information
    transmission as it is necessary to provide our services and live up to our
    Terms of Service.
  </p>
  <p>
    https://dznhtt1ebe.execute-api.us-east-2.amazonaws.com is the Amazon AWS
    cloud service address for GoDuck to complete its services. This address is
    only used by GoDuck to store information that the User is aware of and has
    agreed to authorize. Amazon AWS cloud-related services are managed and
    maintained by the GoDuck development team only. GoDuck, as stated in the
    privacy agreement, will not share the information stored on
    https://dznhtt1ebe.execute-api.us-east-2.amazonaws.com, or transmitted it to
    any third party to use.
  </p>
  <h2>Information Sercurity</h2>
  <p>
    We urge you to protect your own privacy. We recommend that you do not share
    your GoDuck VPN password with anyone or in any unsolicited phone call or
    email.
  </p>
  <p>
    All public and private keys and passwords are secured securely using strong
    cryptographic algorithms where necessary. Appropriate security measures are
    taken for the protection of this information against unauthorized access,
    unauthorized alteration, destruction or disclosure of data. Access to
    personal information by GoDuck VPN employees, contractors and agents is
    restricted. These officials require that information in order to operate,
    develop or improve our services but are bound by confidentiality
    obligations. They may be subjected to disciplinary action, which includes
    termination and criminal prosecution, upon failing to meet these
    obligations.
  </p>
  <h2>Cookies</h2>
  <p>
    GoDuck VPN uses a few types of different cookies to improve the user
    experience on the website, such as:
  </p>
  <ul>
    <li>
      Google Analytics for statistical assessment and website performance
      improvement;
    </li>
    <li>
      Affiliate cookies to identify the customers referred to the Site by our
      partners, so that we can grant the referrers with commissions;
    </li>
    <li>
      Cookies for personalizing the content of the Site for users, such as
      setting the default language.
    </li>
  </ul>
  <p>
    You can set up warnings for every time the Site places a cookie in your
    browser, or you can choose to disable all cookies. You can do both through
    your browser settings. Since each browser has a different procedure for
    managing cookies, look at your browser’s Help Menu to learn the correct way
    to do it.
  </p>
  <p>
    Alternatively, you can disable all cookies by visiting the Network
    Advertising Initiative Opt Out page or by using the Google Analytics Opt Out
    Browser add-on. Please note that choosing to disable cookies may negatively
    affect some of the features that make your Site experience more efficient.
  </p>
  <h2>Third-Party Websites</h2>
  <p>
    The Site may contain links to external websites that do not fall under
    GoDuck VPN’s domain. GoDuck VPN is not responsible for the privacy practices
    or content of such external websites.
  </p>
  <h2>Consent and Age Restrictions</h2>
  <p>
    By using the Site, Content, Apps, Software, or Services, you agree to have
    your information handled as described in our Terms of Service and Privacy
    Policy.
  </p>
  <p>
    The Services are intended for adults aged 18 and above. If you believe your
    child has provided information to us, please let us know immediately.
  </p>
  <h2>Jurisdiction and Applicable Law</h2>
  <p>
    Keeping your information private is our core mission. In service of this
    mission, GoDuck VPN's registered place of business is in Singapore, where
    has integrated data protection legislation, GoDuck network booster complies
    with the laws or the requirements of law enforcement agencies of the
    server’s region.
  </p>
  <p>
    Additionally, we will notify affected users about any requests for their
    account information, unless prohibited from doing so by law or court order.
  </p>
  <h2>Users in the European Union</h2>
  <p>
    GoDuck VPN is committed to user privacy globally, and our existing practices
    reflect that through minimal collection of data and ensuring users have
    control over their personal information. The General Data Protection
    Regulation (GDPR) of the European Union (EU) requires us to outline those
    practices in a specific manner for users in the EU.
  </p>
  <p>
    In line with the GDPR, we collect and process the data outlined in this
    Privacy Policy on one of the following bases, depending on the
    circumstances:
  </p>
  <ol>
    <li>
      For the purposes of fulfilling our contractual obligations to users,
      including:
    </li>
    <li>Providing users with the Services and Apps they have requested.</li>
    <li>Managing user subscriptions and processing payments.</li>
    <li>Providing customer support.</li>
    <li>
      For a legitimate interest associated with the operation of our business,
      including:
    </li>
    <li>
      Enhancing the quality, reliability, and effectiveness of our Site,
      Services, and Apps.
    </li>
    <li>
      Communicating with customers to provide information and seek feedback
      related to our Services and Apps.
    </li>
    <li>With the consent of users, which users can withdraw at any time.</li>
  </ol>
  <p>
    You can exercise your rights under the GDPR to access, transfer, correct,
    delete, or object to the processing of your personal information by
    contacting us at
    <a style="color: #e8aa10" href="mailto:info@goduck.io"> info@goduck.io </a>
  </p>
  <h2>PERSONAL INFORMATION</h2>
  <p>
    Development and Innovative for Go Duck VPN are not available for the
    information related to the personal information of users, including but not
    limited to names (user names and screen names), email addresses, including
    your privacy settings, and the products and features you use for the purpose
    to enjoy our VPN services. Except for the limited exceptions, we don't
    automatically collect any Personal Information from you.
  </p>
  <p>
    When you open the APP seeking for our VPN services, the APP assigns a unique
    identifier to you for the service (such unique identifier just binds up with
    your device identification code, but does not tie to any other personal
    information of users). When you use the VPN services, we will check your
    account information through this unique identifier to determine the level of
    service you have signed up for before establishing your VPN connection.
  </p>
  <p>
    If you contact us directly, we may receive additional information about you
    such as your name, email address, the contents of the message and/or
    attachments you may send us, and any other information you may choose to
    provide. We may also receive a confirmation when you open an email from us.
  </p>
  <p>
    Innovative uses your email address (if any depending on your choice) for the
    following reasons:
  </p>
  <ul>
    <li>
      To communicate with you about your VPN services or respond to your
      communications;
    </li>
    <li>To send you updates and announcements;</li>
    <li>
      To send marketing information, such as Innovative offers, surveys,
      invitations, and content about other matters in connection with Innovative
      (‘Marketing emails’). Innovative uses your personal information only for
      the purposes listed in this Privacy Policy, and we don't sell your
      personal information to third parties without your prior consent.
    </li>
  </ul>
  <h2>SERVICE INFORMATION RELATED TO YOUR VPN USAGE</h2>
  <p>
    Subject to applicable laws and for the avoidance to infringe any internet
    service provider, any browsing information, traffic destination, data
    content, IP addresses, DNS queries or other similar information relating to
    your online activities transmitted by you to our servers is encrypted and
    cleared after the VPN ‘session’ is closed. That said, we don't collect any
    information regarding the websites you visit or any data stored on or
    transmitted from your device, including any data that applications on your
    device may transmit through the VPN network. More specifically:
  </p>
  <ul>
    <li>
      We do not know which user ever accessed a particular website or service;
    </li>
    <li>
      We do not know which user was connected to the VPN at a specific time;
    </li>
    <li>
      We do not know the set of original IP addresses of a user's computer or
      the temporarily assigned network addresses.
    </li>
  </ul>
  <p>
    If anyone would like to access to or try to compel Innovative to release
    user information based on any of the above, we cannot supply this
    information because the data don't exist.
  </p>
  <p>
    For the purpose to fulfill our contractual obligations in good faith and to
    make you enjoy excellent VPN services, we collect the following information
    related to your VPN usage:
  </p>
  <p>Information related to App and App's versions</p>
  <p>
    We collect information related to App and App's version(s) you have
    activated. It makes our Support Team efficiently find out and eliminate
    technical issues for you.
  </p>
  <p>Information related to Connection</p>
  <p>
    We collect information about whether a VPN connection is successful. The
    minimal information allows us to provide efficient technical support to you,
    such as identifying connection problems, providing country-specific advice
    about how to use our VPN services, and to enable our engineers to identify
    and fix network issues.
  </p>
  <p>Anonymous Data for Network Diagnostics, Study and Analysis</p>
  <p>
    When you become one of our users for the VPN services, with your permission,
    we collect minimal usage statistics and anonymized analytics data used for
    network diagnostics in our network operations tools, for the purpose to
    optimize network speeds and to let us identify problems related to specific
    apps, VPN servers, or ISPs. Learning and analyzing the statistics allows us
    to improve the quality of the services and the products. We ensure the
    information we receive is fully anonymized and cannot be tied back to
    individual users (i.e., we do not store which user sent which data, and we
    do not store IP addresses).
  </p>
  <p>We will collect the following information:</p>
  <ul>
    <li>
      Personal Data. Means any information that can be used, alone or combined
      with other data, to uniquely identify an individual. Personal Data is
      collected for the purpose of providing you with Go Duck VPN services. It
      may include the name, email address, and payment information that you
      submit to us when you create or update your Account.
    </li>
    <li>
      Device information. Go Duck VPN uses device information (including device
      type, operating system/language, or user proxy, as well as mobile
      identifiers provided by Android or iOS devices) to generate statistics
      related to the marketing channels and advertising partners through which
      users learned about and signed up for Go Duck VPN Apps. This device
      information does not contain your name, email address, or other Personal
      Data.
    </li>
    <li>
      VPN-Specific Information. Our VPN products do not log any information that
      associates your account credentials or identity with your VPN session
      activity. We do not maintain any records that show what websites you were
      browsing or apps accessed through a VPN connection. However, we will：
      <ul>
        <li>Record the amount of bandwidth used in a VPN session</li>
        <li>Identify the locations of the VPN servers utilized</li>
        <li>Collect similar diagnostic information as referenced above</li>
        <li>
          Ask you to rate your connection and provide some more detailed survey
          information if you are not satisfied with the service. This
          information is not tied to a specific user.
        </li>
      </ul>
    </li>
    <li>
      Diagnostic information about if and how a VPN connection attempt failed;
    </li>
    <li>Speed test data;</li>
    <li>
      Crash reports, also without any personally identifiable information. These
      are handled in an anonymized form by third parties, dependent on the
      platform you are using. On i0S, Apple's crash reporting can be turned off
      in iOS settings.
    </li>
  </ul>
  <p>Conclusion</p>
  <p>
    We collect minimal usage statistics to maintain our quality of service.
    Although we provide service to users improving the network speed, we can't
    uniquely identify any specific behavior of any user.
  </p>
  <p>
    We stand by our firm commitment to our customers’ privacy by not possessing
    any data related to a user's online activities.
  </p>
  <h2>THE USE AND SHARING OF INFORMATION</h2>
  <p>How we use IP Addresses</p>
  <p>
    As written above, we do not log or otherwise record IP addresses in
    connection with your VPN browsing activity. In the interest of information
    transparency, we do collect and use IP addresses in these other contexts：
  </p>
  <ul>
    <li>Protecting against fraud with financial transactions with us ;</li>
    <li>
      Deriving non-identifiable items of information, such as your approximate
      geographic location and information about your network service provider or
      carrier (we use this information to provide a better user experience, such
      as by connecting you with VPN servers nearer to you and by analyzing the
      connection quality with different network service providers) ;
    </li>
    <li>
      Enabling us to attribute our advertisements and service features to
      actions taken in response to such advertisements and service features.
    </li>
  </ul>
  <p>How we use information</p>
  <p>We use the information we collect in various ways, including to:</p>
  <ul>
    <li>Provide, operate, and maintain our Services;</li>
    <li>Improve, personalize, and expand our Services;</li>
    <li>Understand and analyze how you use our Services;</li>
    <li>Develop new products, services, features, and functionality;</li>
    <li>Process your transactions; • Find and prevent fraud.</li>
  </ul>
  <p>How we share information</p>
  <p>
    We may share the information we collect in various ways, including the
    following:
  </p>
  <p>
    Information We Share When You Sign Up Through a Referral. If you sign up for
    our Services through a referr from a friend, we may share information with
    your referrer to let them know that you used their referral to sign up for
    our Services.
  </p>
  <p>
    Analytics. We collect minimal usage statistics and anonymized analytics data
    used for network diagnostics in our network operations tools. Please see
    more information in Section 2 of this Privacy Policy.
  </p>
  <p>
    Business Transfers. Information may be disclosed and otherwise transferred
    to any potential acquirer, successor, or assignee as part of any proposed
    merger, acquisition, debt financing, sale of assets, or similar transaction,
    or in the event of insolvency, bankruptcy, or receivership in which
    information is transferred to one or more third parties as one of our
    business assets.
  </p>
  <h2>Changes to the Privacy Policy</h2>
  <p>
    We may change our Privacy Policy from time to time, without prior notice to
    you, consistent with applicable privacy laws and principles. Your continued
    use of the Site or Services constitutes your acceptance of our Privacy
    Policy.
  </p>
  <h2>How to Contact US</h2>
  <p>
    If you have any questions regarding our Privacy Policy and how we handle
    your information, please feel free to contact us at the following email
    address:
    <a style="color: #e8aa10" href="mailto:info@goduck.io">info@goduck.io</a>
  </p>
</template>
